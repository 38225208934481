import * as Yup from 'yup';

const emailCheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SubscribeFormSchema = Yup.object().shape({
  email: Yup.string()
    .matches(emailCheck, 'Please enter a valid email address')
    .email('Wrong email')
    .required('This field is required'),
});

export default SubscribeFormSchema;
