import * as React from 'react';
import './Articles.scss';
import { BlogCard } from '../../_Shared/BlogCard/BlogCard';
import { CARD_STYLES } from '../../_Shared/BlogCard/BlogCard';
import { AuthorHeaderData } from '../types';
import { countArticleReadingTime } from '../../../utils/utilsReadingTime';

const Articles = ({ sanityAuthor }: AuthorHeaderData) => {
  const authorArticles = sanityAuthor.authorArticles;

  return (
    <div className="authorArticles">
      <div className="container">
        {authorArticles.map(
          (
            {
              slug: { current },
              image: {
                asset: { url },
              },
              categories,
              title,
              _rawContent,
              id,
            },
            index: number
          ) => {
            const categoryTitle = categories[0].title;
            const arrayOfSymbols: number[] = [];

            countArticleReadingTime(_rawContent, arrayOfSymbols);
            const countOfSymbols = arrayOfSymbols.reduce((acc, element) => acc + element, 0);
            const readingTime = +(countOfSymbols / 1200).toFixed(0);

            return (
              <BlogCard
                slug={current}
                key={index}
                time={readingTime}
                image={url}
                articleId={id}
                categories={categoryTitle}
                description={title}
                className={CARD_STYLES.CARD_STYLES_MEDIUM}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default Articles;
