import React, { useState } from 'react';
import Lines from '../Lines/Lines';
import { BTN_STYLES, Button } from '../Button/Button';
import { Formik, Form, Field } from 'formik';
import SubscribeFormSchema from './subscribeFormSchema';
import { useFirebaseDatabase } from '../../../hooks/useFirebaseDatabase';
import './SubscribeForm.scss';

interface SubscribeFormProps {
  title?: string;
  className?: string;
  linesIsActive?: boolean;
}

const SubscribeForm = ({ title = 'Stay Connected', className, linesIsActive = true }: SubscribeFormProps) => {
  const { saveEmail } = useFirebaseDatabase();
  const [, setEmailValue] = useState<string>('');
  const [subscription, setSubscription] = useState<boolean>(false);
  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={SubscribeFormSchema}
      onSubmit={async (values, { resetForm }) => {
        await saveEmail(values);

        resetForm({
          values: { email: 'Successfully subscribed!' },
        });

        setSubscription(true);
      }}
    >
      <Form className="subscribeForm">
        {linesIsActive && <Lines className="line__wrapper_small" />}
        <div className={className ? className : 'subscribeForm__container'}>
          <span>{linesIsActive && title}</span>
          <div className="subscribeForm__feedback">
            <label htmlFor="email_input">Email Input</label>
            <Field
              id="email_input"
              name="email"
              type="email"
              placeholder="Email Address"
              onBlur={onEmailChange}
              className={subscription ? 'isSubscribed' : ''}
            />
            <Button type="submit" title="Subscribe" className={BTN_STYLES.BTN_PRIMARY_MEDIUM} />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default SubscribeForm;
