import * as React from 'react';
import AuthorSmm from '../AuthorSmm/AuthorSmm';
import './AuthorCard.scss';
import { MessengersData } from '../../types';

interface AuthorCardProps {
  authorAvatar: string;
  authorPosition: string;
  authorCompany: string;
  authorCompanyLink: string;
  socialLinks: MessengersData[];
}

const AuthorCard = ({
  authorAvatar,
  authorPosition,
  socialLinks,
  authorCompany,
  authorCompanyLink,
}: AuthorCardProps) => {
  return (
    <div className="authorHeader__contentLeft">
      <div className="authorAvatarWrapper">
        <img className="authorAvatar" src={authorAvatar} alt="Author photo" />
        <p className="authorPosition">{authorPosition}</p>
        <a target="_blank" href={authorCompanyLink} className="authorCompany">
          {authorCompany}
        </a>
      </div>
      <AuthorSmm socialLinks={socialLinks} />
    </div>
  );
};

export default AuthorCard;
