import email from './svg/email.svg';
import note from './svg/note.svg';
import pencil from './svg/pencil.svg';
import smileBad from './svg/smile_bad.svg';
import smileOk from './svg/smiley_ok.svg';
import smileGood from './svg/smile_good.svg';
import smilVeryGood from './svg/smile_very_good.svg';
import smileExcellent from './svg/smile_excellent.svg';

const SVG_ICONS_AUTHOR = {
  EMAIL: email,
  NOTE: note,
  PENCIL: pencil,
  SMILE_BAD: smileBad,
  SMILE_OK: smileOk,
  SMILE_GOOD: smileGood,
  SMILE_VERY_GOOD: smilVeryGood,
  SMILE_EXCELLENT: smileExcellent,
};

export default SVG_ICONS_AUTHOR;
