import React from 'react';
import './Lines.scss';
import { lines } from '../../../constants/defaultData';

interface LinesProps {
  className?: string;
  data?: {
    [key: string]: undefined;
  }[];
}

const Lines = ({ className, data = lines }: LinesProps) => {
  return (
    <>
      <div className={className || 'line__wrapper'}>
        {data?.map((_, index) => {
          return <div className="line" key={index} />;
        })}
      </div>
    </>
  );
};

export default Lines;
