import * as React from 'react';
import './AuthorSmm.scss';
import { MessengersData } from '../../types';

interface AuthorSmmProps {
  socialLinks: MessengersData[];
}

const AuthorSmm = ({ socialLinks }: AuthorSmmProps) => {
  return (
    <div className="authorSmm__wrapper">
      {socialLinks.map(({ image: { image, alt, caption } }, index) => {
        const { asset } = image;
        const { url } = asset;

        return (
          <div key={index}>
            <a target="_blank" href={caption}>
              <img className="authorSmm__wrapperItem" src={url} alt={alt} />
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default AuthorSmm;
