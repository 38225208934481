import React from 'react';
import './BlogCard.scss';
import '../Budge/Budge.scss';
import { Budge } from '../Budge/Budge';
import SVG_ICONS_SHARED from '../../../images/_shared/svg';
import { Link } from 'gatsby';
import ROUTES from '../../../constants/routes';

interface BlogCardProps {
  title?: string | null;
  description: string;
  image: string;
  time?: number;
  categories?: string;
  className: string;
  slug?: string;
  articleId: string;
}

export const CARD_STYLES = {
  CARD_STYLES_SMALL: 'card cardSmall',
  CARD_STYLES_MEDIUM: 'card cardMedium',
  CARD_STYLES_LARGE: 'card cardLarge',
};

export const BlogCard = ({
  time,
  title: header,
  description,
  image,
  className,
  categories,
  slug,
  articleId,
}: BlogCardProps) => {
  return (
    <div className={className || 'card'}>
      <div className="card_description">
        <Budge category={categories} time={`${time} ` + 'min'} image={SVG_ICONS_SHARED.CLOCK} id={articleId} />
        {className === 'card cardLarge' ? (
          <Link to={ROUTES.BLOG + `${slug}`}>{header}</Link>
        ) : (
          <Link to={ROUTES.BLOG + `${slug}`}>{description}</Link>
        )}
        {className === 'card cardLarge' && <p>{description}</p>}
      </div>
      <div className="card_content">
        <img loading="lazy" src={image} alt="card image" />
      </div>
    </div>
  );
};
