import React from 'react';
import StarRating from '../StarRating/StarRating';
import { Link } from 'gatsby';

interface BudgeProps {
  id: string;
  category?: string;
  time: string;
  image?: string;
  navigation?: string | undefined;
  categoryId?: number;
}

export const Budge = ({ time, category, image, id, navigation, categoryId }: BudgeProps) => {
  const categoryHandler = () => {
    localStorage.setItem('category', `${categoryId}`);
  };

  const renderImage = () => {
    if (image) {
      return <img loading="lazy" src={image} alt="size" />;
    }
  };

  return (
    <div className="description">
      <div className="description__title">
        {navigation !== undefined ? (
          <Link onClick={categoryHandler} to={navigation}>
            {category}
          </Link>
        ) : (
          <span>{category}</span>
        )}
      </div>
      <div className="description__title">
        {renderImage()}
        <span>{time}</span>
      </div>
      <div className="description__title">
        <StarRating id={id} />
      </div>
    </div>
  );
};
