import * as React from 'react';
import PageLayout from '../../components/_Shared/PageLayout/PageLayout';
import 'normalize.css';
import '../../globalStyles/_fonts.scss';
import '../../globalStyles/_styles.scss';
import { graphql } from 'gatsby';
import AuthorContent from '../../components/Author';
import { AuthorContentData } from '../../components/Author/types';

export const query = graphql`
  query MyAuthor($id: String) {
    sanityAuthor(id: { eq: $id }) {
      fullName
      position
      email
      id
      experience
      _rawAuthorBio
      messengers {
        image {
          caption
          alt
          image {
            asset {
              url
            }
          }
        }
      }
      company {
        companyName
        companyLink
      }
      seo {
        description
        title
      }
      avatar {
        asset {
          url
        }
      }
      slug {
        current
      }
      authorArticles {
        id
        title
        time
        categories {
          title
        }
        image {
          asset {
            url
          }
        }
        slug {
          current
        }
        _rawContent
      }
    }
    allSanityFooter {
      nodes {
        title
        footerRow {
          title
          link
        }
      }
    }
  }
`;

const Author = (props: AuthorContentData) => {
  const authorContentData = props.data;
  const seoTitle = props.data.sanityAuthor.seo.title;
  const seoDescription = props.data.sanityAuthor.seo.description;
  const { allSanityFooter } = props.data;

  return (
    <PageLayout
      title={seoTitle}
      footerData={allSanityFooter}
      description={seoDescription}
      markData={authorContentData.sanityAuthor}
    >
      <AuthorContent data={authorContentData} />
    </PageLayout>
  );
};

export default Author;
