import * as React from 'react';
import './Desctription.scss';
import SVG_ICONS_AUTHOR from '../../../images/author/svg';
import { AuthorHeaderData } from '../types';

const Description = ({ sanityAuthor }: AuthorHeaderData) => {
  const amountArticles = sanityAuthor.authorArticles.length;
  const experience = sanityAuthor.experience;

  return (
    <div className="authorDescription">
      <div className="authorDescriptionWrapper">
        <div className="containerDescription">
          <img src={SVG_ICONS_AUTHOR.NOTE} alt="calendar icon" />
          <span>{amountArticles} Articles</span>
        </div>
        <div className="containerDescription">
          <img src={SVG_ICONS_AUTHOR.PENCIL} alt="reading time icon" />
          <span>{experience} year of experience </span>
        </div>
      </div>
    </div>
  );
};

export default Description;
