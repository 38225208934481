import React, { useEffect, useState } from 'react';
import SVG_ICONS_SHARED from '../../../images/_shared/svg';
import { useFirebaseDatabase } from '../../../hooks/useFirebaseDatabase';

interface StarRatingProps {
  [key: string]: string;
}

const StarRating = ({ id }: StarRatingProps) => {
  const [ratingData, setRatingData] = useState<any>();
  const [rating, setRating] = useState<number>(0);
  const { getRating } = useFirebaseDatabase();

  useEffect(() => {
    getRating(id).then((data) => setRatingData(data));
  }, []);

  useEffect(() => {
    if (ratingData) {
      const length = ratingData?.data.rating.length;
      const rating = ratingData?.data.rating.reduce((el: number, item: number) => el + item, 0) / length;

      setRating(rating);
    }
  }, [ratingData]);

  return (
    <>
      <img loading="lazy" src={SVG_ICONS_SHARED.STAR} alt="rating" />
      {ratingData && <span>{rating.toFixed(1)}</span>}
    </>
  );
};

export default StarRating;
