import * as React from 'react';
import './index.scss';
import Header from './Header/Header';
import { AuthorContentData } from './types';
import Description from './Description/Description';
import Articles from './Articles/Articles';

const AuthorContent = ({ data }: AuthorContentData) => {
  const { sanityAuthor, allSanityFooter } = data;

  return (
    <section className="author">
      <Header sanityAuthor={sanityAuthor} allSanityFooter={allSanityFooter} />
      <Description sanityAuthor={sanityAuthor} allSanityFooter={allSanityFooter} />
      <Articles sanityAuthor={sanityAuthor} allSanityFooter={allSanityFooter} />
    </section>
  );
};

export default AuthorContent;
