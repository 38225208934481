import * as React from 'react';
import './Header.scss';
import AuthorCard from './AuthorCard/AuthorCard';
import AuthorContentRight from './AuthorContentRight/AuthorContentRight';
import { AuthorHeaderData } from '../types';
import BreadCrumbs from '../../_Shared/Breadcrumbs/Breadcrumbs';

const Header = ({ sanityAuthor }: AuthorHeaderData) => {
  const {
    avatar: {
      asset: { url },
    },
    company: { companyName, companyLink },
    email,
    fullName,
    position,
    messengers,
    _rawAuthorBio: biography,
  } = sanityAuthor;

  return (
    <div className="authorHeader">
      <div className="author__breadCrumbs">
        <BreadCrumbs />
      </div>
      <div className="authorHeader__container">
        <AuthorCard
          authorAvatar={url}
          authorPosition={position}
          socialLinks={messengers}
          authorCompany={companyName}
          authorCompanyLink={companyLink}
        />
        <AuthorContentRight authorBio={biography} email={email} fullName={fullName} />
      </div>
    </div>
  );
};

export default Header;
