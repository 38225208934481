import * as React from 'react';
import './AuthorContentRight.scss';
import SubscribeForm from '../../../_Shared/SubscribeForm/SubscribeForm';
import usePortableText from '../../../../hooks/usePortableText';

interface AuthorContentRightProps {
  fullName: string;
  email: string;
  authorBio: any;
}

const AuthorContentRight = ({ fullName, email, authorBio }: AuthorContentRightProps) => {
  const { renderPortableText } = usePortableText();

  return (
    <div className="authorHeader__contentRight">
      <h1>{fullName}</h1>
      <a>{email}</a>
      {renderPortableText(authorBio)}
      <SubscribeForm className="withoutLine" linesIsActive={false} />
    </div>
  );
};

export default AuthorContentRight;
